import React from "react"
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import LocaleContext from '../contexts/LocaleContext'
// 

export default function InvestorRelationsMenu({ className }) {
  InvestorRelationsMenu.propTypes = {
    className: PropTypes.string
  }

  const language = React.useContext(LocaleContext).language

  const svLinks = [
    {
      to: "/investerarrelationer",
      title: "Översikt"
    },
    {
      to: "/investerarrelationer/kalender",
      title: `Finansiell kalender`
    },
    {
      to: "/investerarrelationer/substansvarde",
      title: "Substansvärde"
    },
    {
      to: "/investerarrelationer/preferensaktier",
      title: "Preferensaktier"
    },
    {
      to: "/investerarrelationer/inlosen",
      title: "Inlösen"
    },
    {
      to: "/investerarrelationer/esg",
      title: "ESG"
    },
    {
      to: "/investerarrelationer/rapporter-presentationer",
      title: `Finansiella rapporter & presentationer`
    },
    {
      to: "/investerarrelationer/press-nyheter",
      title: `Press & nyheter`
    },
    {
      to: "/investerarrelationer/bolagsstyrning",
      title: `Bolagsstyrning`,
      subItems: [
        {
          to: '/bolagsordning',
          title: 'Bolagsordning'
        }, {
          to: '/bolagsstamma',
          title: 'Bolagsstämma'
        }, {
          to: '/styrelse',
          title: 'Styrelse'
        }, {
          to: '/ledning',
          title: 'Ledning'
        }, {
          to: '/agarstruktur',
          title: 'Ägarstruktur'
        }, {
          to: '/revisor',
          title: 'Revisor'
        },
        {
          to: '/advisor',
          title: 'Certified advisor'
        }
      ]
    },
    {
      to: "/ipo",
      title: `IPO 2021`
    },
    {
      to: "/investerarrelationer/kontakt",
      title: `IR kontakt`
    },
  ]

  const enLinks = [
    {
      to: "/en/investerarrelationer",
      title: "Overview"
    },
    {
      to: "/en/investerarrelationer/kalender",
      title: `Financial Calendar`
    },
    {
      to: "/en/investerarrelationer/substansvarde",
      title: "Net Asset Value"
    },
    {
      to: "/en/investerarrelationer/preferensaktier",
      title: "Preferred Stocks"
    },
    {
      to: "/en/investerarrelationer/inlosen",
      title: "Redemption"
    },
    {
      to: "/en/investerarrelationer/esg",
      title: "ESG"
    },
    // {
    //   to: "/en/investerarrelationer/policy-for-operativa-kostnader",
    //   title: "Policy for operational costs"
    // },
    {
      to: "/en/investerarrelationer/rapporter-presentationer",
      title: `Financial Reports & Presentations`
    },
    {
      to: "/en/investerarrelationer/bolagsstyrning",
      title: `Corporate Governance`,
      subItems: [
        {
          to: '/bolagsordning',
          title: 'Articles of Association'
        }, {
          to: '/bolagsstamma',
          title: 'General Meeting'
        }, {
          to: '/styrelse',
          title: 'Board'
        }, {
          to: '/ledning',
          title: 'Management'
        }, {
          to: '/agarstruktur',
          title: 'Ownership Structure'
        }, {
          to: '/revisor',
          title: 'Auditor'
        },
        {
          to: '/advisor',
          title: 'Certified Advisor'
        }
      ]
    },
    {
      to: "/en/investerarrelationer/kontakt",
      title: `IR Contact`
    },
  ]

  let links = language=="sv" ? svLinks : enLinks

  return (
    <ul className={`list-simple ${className || ''}`} tabIndex="0" aria-label="Investor relations menu" role="menu">
      {links.map(link => (
        <li key={link.title}>
          <Link className="link-simple" to={link.to}>{link.title}</Link>
          {
            link.subItems && (
              <ul className="list-simple">
                {link.subItems.map(sublink => (
                  <li key={sublink.title}>
                    <Link className="link-simple" to={`${link.to}${sublink.to}`}>{sublink.title}</Link>
                  </li>
                ))}
              </ul>)
          }
        </li>
      ))}
    </ul>
  )
}
