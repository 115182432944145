import React from "react"
import InvestorRelationsLayout from '../../components/InvestorRelationsLayout'
import { graphql } from 'gatsby'
import LocaleContext from '../../contexts/LocaleContext'
import Layout from '../../components/Layout'
import Menu from '../../components/Menu'
import Footer from '../../components/Footer'

export const query = graphql`
  query ($locale: String!){
    datoCmsInvestorRelationsPage ( locale: { eq: $locale }){
      calendar {
        id
        date
        event
      }
    }
    datoCmsNewMenu ( locale: { eq: $locale }){
      ...Menu
    }
    datoCmsFooter ( locale: { eq: $locale }) {
      ...Footer
    }
  }
  `

export default function Calendar({ data, pageContext }) {
  const { calendar } = data.datoCmsInvestorRelationsPage

  const calendarGroupedByYear = calendar.reduce((acc, calendarItem) => {
    const year = new Date(calendarItem.date).getFullYear()
    if (acc[year]) acc[year].push(calendarItem)
    else acc[year] = [calendarItem]
    return acc
  }, {})

  return (

    <LocaleContext.Provider value={pageContext.locale || 'en'}>
      <Layout transparentMenu locale={pageContext.locale}>
        <Menu
            transparent={true}
            menu={data.datoCmsNewMenu}
            socialMedia={data.datoCmsFooter.socialMedia}
          />
        <InvestorRelationsLayout>
          <React.Fragment>
            <CalendarPageTitle />
            {Object.entries(calendarGroupedByYear).sort(([a], [b]) => b - a).map(([year, events]) => (
              <section key={year}>
                <h3 className="margin-top-small">{year}</h3>
                <table>
                  <tbody>
                    {events.sort(({ date: a }, { date: b }) => (new Date(a) - new Date(b)))
                      .map(({ date, event, id }) => (
                        <tr key={id}>
                          <DatesLocalised date={date} />
                          <td>{event}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </section>
            ))}
          </React.Fragment>
        </InvestorRelationsLayout>
        <Footer footer={data.datoCmsFooter} />
      </Layout>
    </LocaleContext.Provider>
  )
}

const DatesLocalised = (date) => {
  const language = React.useContext(LocaleContext).language
  return (
    <td style={{ width: '9em' }}><b>{new Date(date.date).toLocaleDateString(language, { day: 'numeric', month: 'long' })}</b></td>
  )
}

const CalendarPageTitle = () => {
  const language = React.useContext(LocaleContext).language
  return (
    <h2>{language=="sv" ? 'Finansiell kalender' : 'Financial calendar'}</h2>
  )
}