import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { HelmetDatoCms } from 'gatsby-source-datocms'
import StockInfo from '../components/StockInfo'
import LocaleContext from '../contexts/LocaleContext'
import Hero from '../components/Hero'
import InvestorRelationsMenu from './InvestorRelationsMenu'


export default function Header({ children, sidebar }) {
  return (
    <StaticQuery
      query={graphql`
        query InvestorRelationsLayoutQuery {
          datoCmsInvestorRelationsPage {
            seoMetaTags {
              tags
            }
            heroTextNew
            heroBackground {
              fluid(maxWidth: 2560, imgixParams: { fm: "jpg", auto: "compress" }) {
                ...GatsbyDatoCmsFluid
              }
            }
            heroActions
          }
        }
      `}
      render={data => (
        <React.Fragment>
          <HelmetDatoCms seo={data.datoCmsInvestorRelationsPage.seoMetaTags} />
          <Hero heroBackground={data.datoCmsInvestorRelationsPage.heroBackground} className="ir-hero">
            <div
              dangerouslySetInnerHTML={{
                __html: data.datoCmsInvestorRelationsPage.heroTextNew,
              }} />

            <StockInfo />
            <HeroActions />

          </Hero>
          <div className="no-overflow padding-bottom-big">
            <div className="grid grid--no-margin">
              <div className="col col--md-3">
                <div className="small"><InvestorRelationsMenu className="ir-menu" /></div>
              </div>
              <div className="col col--md-9 col--lg-6 prose">
                <div>{children}</div>
              </div>
              <div className="col  col--sm-0 col--lg-3">
                <div className="small">
                  {sidebar}
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    />
  )
}

const HeroActions = () => {
  const language = React.useContext(LocaleContext).language
  return (
    <p>
      {language=='sv' ? <a class="btn btn--ghost" href="/sv/investeringsstrategi">Investeringsstrategi</a>
      : <a class="btn btn--ghost" href="/en/investeringsstrategi">Investment Strategy</a>}
    </p>
  )
}